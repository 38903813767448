<template lang="pug">
.page
  el-card.filterCard
    el-form(inline label-width="6em")
      el-form-item(label="搜索")
        el-input(v-model="filter.search")
      el-form-item()
        el-button(type="primary" @click="handleSearch") 搜索
        el-button(type="warning" @click="initData") 重置
  el-card.tableCard
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="wechatInfo.openId" label="微信OpenId")
      el-table-column(prop="extendInfo.nickName" label="昵称")
      el-table-column(prop="phone" label="手机")
      el-table-column(prop="createTime" label="注册时间")
      //- el-table-column(fixed="right" label="操作" width="120")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)")
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain)
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="handleSearch"
        @current-change="getList")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const { fetch, router } = inject('global')

const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const filter = ref({
  search: ''
})

function handleSearch() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function initData() {
  filter.value.search = ''
  handleSearch()
}
function getList() {
  let params = {
    ...pagination.value
  }
  if (filter.value.search) {
    params.search = filter.value.search
  }
  fetch.get('/user', { params }).then(res => {
    list.value = res.list
    pagination.value.total = res.page.total
  })
}

function handleEdit(val) {}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
